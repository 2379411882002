import Link from "next/link";
import { Col } from "react-bootstrap";
import HeadCategories from "./category";
import Location from "./location";
const NavMenus = () => {
	return (
		<>
			<Col xs="auto ms-2 me-3 d-none d-md-block">
				<Location />
			</Col>
			<div className="desktop-menu d-flex align-items-center me-2">
				<ul className="d-flex align-items-center p-0 mb-0">
					{Items.map((x) => (
						<li>
							<Link href={x.href}>
								<a href={x.href} className="px-3">
									{x.icon}
									<span>{x.title}</span>
								</a>
							</Link>
						</li>
					))}
					{/* <li>
            <HeadCategories />
          </li> */}
				</ul>
			</div>
		</>
	);
};

export default NavMenus;

const Items = [
	{
		title: "All Products",
		href: "/products",
	},
	{
		title: "Mens",
		href: "/search?type=tool_rental",
	},
	{
		title: "Womens",
		href: "/account/service?insert=true",
	},
];
