import { Col, Container, Row } from "react-bootstrap";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { FaFacebookF, FaPinterestP } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { Logo } from "../shared/common";
const Footer = () => {
	return (
		<footer>
			<div className="bg-light text-white text-center py-3">
				<Container className="home-container">
					<Row className="home-container justify-content-between">
						<Col sm="auto">
							<Row>
								{social.map((s) => (
									<Col>
										<a href={s.href} className="fs-4" target="_blank">
											{s.icon}
										</a>
									</Col>
								))}
							</Row>
						</Col>
						<Col sm="auto">
							<a href="" className="text-dark font-lg">
								Scoll To Top
							</a>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="bg- border-top border-white border-2 py-5">
				<Container className="home-container">
					<Row className="mb-5 align-items-end">
						<Col sm="2">
							<div className="logo px-3">
								<Link href="/">
									<a href="/" className="navbar-brand">
										<Logo />
									</a>
								</Link>
							</div>
						</Col>
						{/* <Col sm="auto">
							<Row className="align-items-center">
								{quicklinks.map((s, i) => (
									<Col sm="auto">
										<Link href={s.href}>
											<a href={s.href} className="text-dark font-lg d-flex align-items-center">
												<span className="me-3 pb-2 fw-bold">{i === 0 ? "" : "."}</span>
												{s.title}
											</a>
										</Link>
									</Col>
								))}
							</Row>
						</Col> */}
					</Row>
					<div className="py-4 border-top border-2 border-light">
						<h3 className="fw-600  fs-6">About</h3>
						<p className="font-e-lg">
							Welcome to Mescadesigners – Your Ultimate Destination for Authentic Fashion Elegance!
							<br />
							<br />
							At Mescadesigners, we believe that fashion is an art form, a way to express individuality, and a celebration of authenticity. Established with a passion for curating the finest in
							clothing, jewelry, and fashionware, we bring you a unique online shopping experience that seamlessly blends our own brand with authentic designs from renowned fashion creators.
							<br />
							<br />
							Our Story: Mescadesigners was born out of a shared vision to redefine the online fashion retail landscape. Founded by a team of fashion enthusiasts and driven entrepreneurs, our store is
							a manifestation of the belief that every individual deserves access to high-quality, stylish, and authentic fashion pieces. We are committed to offering a carefully curated selection
							that speaks to the diverse tastes and preferences of our discerning customers.
							<br />
							<br />
							Our Mission: At the core of Mescadesigners lies a mission to empower individuals to express their true selves through fashion. We aim to provide a platform where you can discover and
							embrace your unique style, confident in the knowledge that every piece in our collection is a testament to quality, craftsmanship, and originality. Our commitment to authenticity extends
							beyond our own brand, as we collaborate with renowned designers to bring you the latest trends and timeless classics.
							<br />
							<br />
							What Sets Us Apart: Own Brand Excellence: Mescadesigners takes pride in our in-house brand, where we meticulously design and create fashion pieces that reflect the latest trends and our
							commitment to quality. Authentic Designer Collaborations: We collaborate with authentic designers to showcase their creations, offering you a diverse range of styles that cater to
							various tastes and preferences. Quality Assurance: Each item in our collection undergoes rigorous quality checks to ensure that you receive nothing but the best. We believe in providing
							you with fashion that stands the test of time. Our Collections:
							<br />
							<br />
							Clothing: From chic everyday wear to statement pieces for special occasions, our clothing collection captures the essence of contemporary style and timeless elegance. Jewelry: Discover a
							stunning array of jewelry that adds the perfect finishing touch to your ensemble. Our collection ranges from delicate pieces to bold, statement accessories. Fashion Ware: Explore our
							curated selection of fashion accessories and essentials that elevate your style, from handbags to scarves and beyond. Join the Mescadesigners Community: Embrace the Mescadesigners
							lifestyle and become part of a community that values authenticity, individuality, and the artistry of fashion. Shop with confidence, knowing that each purchase is a step towards
							expressing your unique style journey.
							<br />
							<br />
							Indulge in the world of Mescadesigners – where fashion meets authenticity, and every piece tells a story. Welcome to a realm of timeless elegance and contemporary sophistication.
						</p>
					</div>
				</Container>
				<div className="bg-light h-100">
					<p className="text-dark fw-600 font-e-lg text-center p-3">Copyright © {new Date().getFullYear()}, Mescadesigners LLC . All Rights Reserved</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

const GetLink = (title, href) => ({ title, href });

const quicklinks = [
	GetLink("Tool Rental", "/search?type=tool_rental"),
	GetLink("Classified", "/classified"),
	GetLink("WishList", "/wishlist"),
	GetLink("Cart", "/cart"),
	GetLink("Browse", "/search"),
	GetLink("Sell On Dealsonopenbox", "https://members.dealsonopenbox.com"),
];

const social = [
	{
		icon: <FaFacebookF className="text-primary" />, //<FaFacebookSquare />,
		href: "/",
	},
	{
		icon: <AiOutlineInstagram className="text-danger" />,
		href: "/",
	},
	{
		icon: <FaPinterestP className="text-danger" />,
		href: "/",
	},
];
