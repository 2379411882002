import dynamic from "next/dynamic";
import Link from "next/link";
import { useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Blob_Base_Url } from "../../utils/static";
import { Logo } from "../shared/common";

import NavMenus from "./menu";

const Action = dynamic(() => import("./action"));
const Mobile = dynamic(() => import("./mobile"));
const Location = dynamic(() => import("./location"));

const Header = () => {
	const head = useRef();

	useEffect(async () => {
		window.addEventListener("scroll", HandleScroll);
		return () => window.removeEventListener("scroll", HandleScroll);
	}, []);

	const HandleScroll = () => {
		if (!head?.current) return;
		if (window.pageYOffset > 80) head.current.classList.add("stick");
		else head.current.classList.remove("stick");
	};

	return (
		<>
			<header className="header" id="#header" ref={head}>
				<Container class="home-container mx-auto">
					<nav className="align-items-center d-flex flex-wrap" id="nav">
						<Mobile />
						<div className="logo me-4">
							<Link href="/">
								<a href="/" className="navbar-brand">
									<Logo />
								</a>
							</Link>
						</div>
						<NavMenus />
						<Action />
					</nav>
				</Container>
			</header>
		</>
	);
};

const menus = [
	{
		title: "Advertise with us",
		href: "mailto:info@dealsonopenbox.com",
	},
	{
		title: "Sell on dealsonopenbox",
		href: "https://members.dealsonopenbox.com",
	},
];

export default Header;
